import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import AnnouncementList from "pages/announcement/AnnouncementList";
import AnnouncementAdd from "pages/announcement/AnnouncementAdd";
// render - dashboard
const DashboardDefault = Loadable(lazy(() => import("pages/dashboard")));

// render - sample page
const SamplePage = Loadable(lazy(() => import("pages/extra-pages/SamplePage")));
const UserAdd = Loadable(lazy(() => import("pages/user/UserAdd")));
const UserList = Loadable(lazy(() => import("pages/user/UserList")));
const MenuAdd = Loadable(lazy(() => import("pages/menu/MenuAdd")));
const MenuList = Loadable(lazy(() => import("pages/menu/MenuList")));
const ContentAdd = Loadable(lazy(() => import("pages/content/ContentAdd")));
const ContentList = Loadable(lazy(() => import("pages/content/ContentList")));
const BlockAdd = Loadable(lazy(() => import("pages/blocks/BlockAdd")));
const BlockList = Loadable(lazy(() => import("pages/blocks/BlockList")));
const NewsAdd = Loadable(lazy(() => import("pages/news/NewsAdd")));
const NewsList = Loadable(lazy(() => import("pages/news/NewsList")));
const SliderAdd = Loadable(lazy(() => import("pages/slider/SliderAdd")));
const SliderList = Loadable(lazy(() => import("pages/slider/SliderList")));
const BannerAdd = Loadable(lazy(() => import("pages/banner/BannerAdd")));
const BannerList = Loadable(lazy(() => import("pages/banner/BannerList")));
const GalleryListAdd = Loadable(
  lazy(() => import("pages/gallery/GalleryListAdd"))
);
const GalleryList = Loadable(lazy(() => import("pages/gallery/GalleryList")));
const LanguageList = Loadable(
  lazy(() => import("pages/language/LanguageList"))
);
const FAQList = Loadable(lazy(() => import("pages/faq/FAQList")));
const FAQAdd = Loadable(lazy(() => import("pages/faq/FAQAdd")));

// render - utilities
const Typography = Loadable(
  lazy(() => import("pages/components-overview/Typography"))
);
const Color = Loadable(lazy(() => import("pages/components-overview/Color")));
const Shadow = Loadable(lazy(() => import("pages/components-overview/Shadow")));
const AntIcons = Loadable(
  lazy(() => import("pages/components-overview/AntIcons"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <DashboardDefault />,
    },
    // {
    //   path: 'color',
    //   element: <Color />
    // },
    // {
    //   path: 'dashboard',
    //   children: [
    //     {
    //       path: 'default',
    //       element: <DashboardDefault />
    //     }
    //   ]
    // },
    {
      path: "sample-page",
      element: <SamplePage />,
    },
    {
      path: "content-page/add",
      element: <ContentAdd />,
    },
    {
      path: "content-page/edit/:id",
      element: <ContentAdd />,
    },
    {
      path: "content-pages",
      element: <ContentList />,
    },
    {
      path: "dynamic-blocks/add",
      element: <BlockAdd />,
    },
    {
      path: "dynamic-blocks/edit/:id",
      element: <BlockAdd />,
    },
    {
      path: "dynamic-blocks",
      element: <BlockList />,
    },
    {
      path: "dynamic-blocks/:id",
      element: <BlockList />,
    },
    {
      path: "gallery-page/add",
      element: <GalleryListAdd />,
    },
    {
      path: "gallery-page/edit/:id",
      element: <GalleryListAdd />,
    },
    {
      path: "gallery-pages",
      element: <GalleryList />,
    },
    {
      path: "slider/add",
      element: <SliderAdd />,
    },
    {
      path: "slider/edit/:id",
      element: <SliderAdd />,
    },
    {
      path: "sliders",
      element: <SliderList />,
    },
    {
      path: "news/add",
      element: <NewsAdd />,
    },
    {
      path: "news/edit/:id",
      element: <NewsAdd />,
    },
    {
      path: "news",
      element: <NewsList />,
    },
    {
      path: "menu/add",
      element: <MenuAdd />,
    },
    {
      path: "menu/edit/:id",
      element: <MenuAdd />,
    },
    {
      path: "menus",
      element: <MenuList />,
    },
    {
      path: "banner/add",
      element: <BannerAdd />,
    },
    {
      path: "banner/edit/:id",
      element: <BannerAdd />,
    },
    {
      path: "banners",
      element: <BannerList />,
    },
    {
      path: "user/add",
      element: <UserAdd />,
    },
    {
      path: "user/edit/:id",
      element: <UserAdd />,
    },
    {
      path: "language",
      element: <LanguageList />,
    },
    {
      path: "faq",
      element: <FAQList />,
    },
    {
      path: "faq/add",
      element: <FAQAdd />,
    },
    {
      path: "faq/edit/:id",
      element: <FAQAdd />,
    },
    {
      path: "users",
      element: <UserList />,
      // element: (await hasRole(userRoles, [1])) ? (
      //   <UserList />
      // ) : (
      //   <UnauthorizedPage />
      // ),
    },
    {
      path: "announcements",
      element: <AnnouncementList />,
    },
    {
      path: "announcement/add",
      element: <AnnouncementAdd />,
    },
    {
      path: "announcement/edit/:id",
      element: <AnnouncementAdd />,
    },
    // {
    //   path: 'shadow',
    //   element: <Shadow />
    // },
    // {
    //   path: 'typography',
    //   element: <Typography />
    // },
    // {
    //   path: 'icons/ant',
    //   element: <AntIcons />
    // }
  ],
};

export default MainRoutes;
