// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.apexcharts-legend-series .apexcharts-legend-marker {
  left: -4px !important;
  top: 2px !important;
}

.jodit-status-bar__item-right a {
  display: none !important;
}

.nestable-item-name {
  padding: 3px;
  border: 1px solid #8079794a;
  border-radius: 8px;
}

.nestable-item::marker {
  display: none !important;
  color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/assets/third-party/apex-chart.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,YAAY;EACZ,2BAA2B;EAC3B,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;EACxB,kBAAkB;AACpB","sourcesContent":[".apexcharts-legend-series .apexcharts-legend-marker {\n  left: -4px !important;\n  top: 2px !important;\n}\n\n.jodit-status-bar__item-right a {\n  display: none !important;\n}\n\n.nestable-item-name {\n  padding: 3px;\n  border: 1px solid #8079794a;\n  border-radius: 8px;\n}\n\n.nestable-item::marker {\n  display: none !important;\n  color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
