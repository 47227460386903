// project import
import Routes from "routes";
import ThemeCustomization from "themes";
import ScrollTop from "components/ScrollTop";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //
const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [jwt, setJwt] = useState(null);

  const unAuthRoutes = ["/login"];

  useEffect(() => {
    checkLoggedInStatus();
  });

  const checkLoggedInStatus = () => {
    if (!unAuthRoutes.includes(window.location.pathname)) {
      if (!localStorage.getItem("user")) {
        window.location.href = "/login";
      }
      setLoggedIn(true);
    }
  };

  const dispatch = useDispatch();

  const handleLogin = (jwtToken) => {
    console.log("test");
    setLoggedIn(true);
    setJwt(jwtToken);
  };

  return (
    <ThemeCustomization>
      <ScrollTop>
        <Routes loggedIn={loggedIn} jwt={jwt} handleLogin={handleLogin} />
      </ScrollTop>
    </ThemeCustomization>
  );
};

export default App;
