// assets
import { ChromeOutlined, QuestionOutlined } from "@ant-design/icons";
const icons = {
  ChromeOutlined,
  QuestionOutlined,
};
// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const pages = {
  id: "page",
  title: "",
  type: "group",
  children: [
    // {
    //   id: "sample-page",
    //   title: "Sample Page",
    //   type: "item",
    //   url: "/sample-page",
    //   icon: icons.ChromeOutlined,
    // },
    {
      id: "user",
      title: "Kullanıcılar",
      type: "item",
      url: "/users",
      icon: icons.ChromeOutlined,
    },
    {
      id: "announcements",
      title: "Duyurular",
      type: "item",
      url: "/announcements",
      icon: icons.ChromeOutlined,
    },
    {
      id: "content-pages",
      title: "Sayfalar",
      type: "item",
      url: "/content-pages",
      icon: icons.ChromeOutlined,
    },
    // {
    //   id: "news",
    //   title: "Haberler",
    //   type: "item",
    //   url: "/news",
    //   icon: icons.ChromeOutlined,
    // },
    // {
    //   id: "gallery-pages",
    //   title: "Galeri Sayfaları",
    //   type: "item",
    //   url: "/gallery-pages",
    //   icon: icons.ChromeOutlined,
    // },
    // {
    //   id: "slider",
    //   title: "Slider",
    //   type: "item",
    //   url: "/sliders",
    //   icon: icons.ChromeOutlined,
    // },
    // {
    //   id: "banner",
    //   title: "Banner",
    //   type: "item",
    //   url: "/banners",
    //   icon: icons.ChromeOutlined,
    // },
    {
      id: "menu",
      title: "Menüler",
      type: "item",
      url: "/menus",
      icon: icons.ChromeOutlined,
    },
    // {
    //   id: "faq",
    //   title: "Sıkça Sorulan Sorular",
    //   type: "item",
    //   url: "/faq",
    //   icon: icons.ChromeOutlined,
    // },
    {
      id: "dynamic-blocks",
      title: "Dinamik Bloklar",
      type: "item",
      url: "/dynamic-blocks",
      icon: icons.ChromeOutlined,
    },
    {
      id: "language",
      title: "Dil Değişkenleri",
      type: "item",
      url: "/language",
      icon: icons.ChromeOutlined,
    },
  ],
};

export default pages;
