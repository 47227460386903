import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Stack,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import strapi from "../../utils/strapi";
import axios from "axios";
import { Link, useLocation, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import TablePagination from "@mui/material/TablePagination";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  AppBar,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  FaxTwoTone,
  SearchOutlined,
} from "../../../node_modules/@mui/icons-material/index";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import Nestable from "react-nestable";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FormatLineSpacingIcon from "@mui/icons-material/FormatLineSpacing";

const styles = {
  position: "relative",
  background: "WhiteSmoke",
  display: "flex",
};
const cssCenter = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const AnnouncementList = () => {
  const user_ = JSON.parse(localStorage.getItem("user"));
  const apiToken = user_.jwt;
  const baseUrl = process.env.REACT_APP_API_KEY;
  const [faqs, setFAQs] = useState([]);
  const { id } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [status, setStatus] = useState("all");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState({});
  const [deleteFAQId, setDeleteFAQId] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [sorterFormData, setSorterFormData] = useState([]);
  const [reversedSorterFormData, setreversedSorterFormData] = useState([]);
  const [show, setShow] = useState(false);
  const [firstFaqId, setFirstFaqId] = useState(0);
  const [lastFaqId, setLastFaqId] = useState(0);
  const [disabledSave, setdisabledSave] = useState(false);
  const [selectedContentTypeValue, setSelectedContentTypeValue] = useState("2");
  const [initValue, setInitValue] = useState({
    title: "",
    description: "",
    content_categories: [],
    order_by: "ASC",
    order_column: "1",
    order_value: 1,
    status: true,
    block_position: "",
    template: "",
    content_type: "",
    // contents: [], Sayfa tarafından eklenecek 25 den fazla veri gelmiyor ondan seçim yapılamıyor
    data_json: "",
    locale: "tr",
  });

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    getFAQs(page, rowsPerPage);
  }, [page, rowsPerPage, status, searchValue, selectedContentTypeValue]);
  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };
  // const handleChangeSelectedContentType = (event) => {
  //   setSelectedContentTypeValue(event.target.value);
  // };
  const handleClickGoAdd = () => {
    window.location.href = "/announcement/add";
  };
  const handleDeleteClick = (faqId) => {
    setDeleteFAQId(faqId);
    setDeleteDialogOpen((prev) => ({ ...prev, [faqId]: true }));
  };

  const handleDeleteConfirm = () => {
    console.log("Delete");
    console.log(deleteFAQId);
    if (deleteFAQId) {
      handleDelete(deleteFAQId);
    }
    setDeleteFAQId(null);
    setDeleteDialogOpen({});
  };

  const handleDeleteCancel = () => {
    setDeleteFAQId(null);
    setDeleteDialogOpen({});
  };

  const handleDelete = async (faqId) => {
    try {
      const data = { data: { status: false } };
      const response = await axios.delete(`${baseUrl}/blocks/${faqId}`, {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      });

      if (response.status === 200) {
        console.log("record deleted successfully.");
        getFAQs(page, rowsPerPage);
      }
    } catch (error) {
      console.error("Error deleting record:", error);
    }
  };
  const EditLink = ({ faqId }) => {
    const editLink = `/announcement/edit/${faqId}`;

    return (
      <Link to={editLink} style={{ textDecoration: "none" }}>
        <IconButton aria-label="edit" style={{ color: "#6c757d" }}>
          <EditIcon />
        </IconButton>
      </Link>
    );
  };

  const getFAQs = async (page = 0, pageSize = 10) => {
    try {
      const page_ = page++;
      const statusSearch =
        searchValue.length > 3
          ? `&filters[title][$containsi]=${searchValue}`
          : "";
      const statusFilter = status !== "all" ? `&filters[status]=${status}` : "";
      //&filters[content_type]=10&[content_type]=11
      const response = await strapi.get(
        `/blocks?pagination[page]=${page}&pagination[pageSize]=${pageSize}${statusFilter}${statusSearch}&filters[content_type]=${selectedContentTypeValue}&sort=order_value:desc&populate=*,block_position,content_type`
      );
      if (response.status === 200 && response.data) {
        setFAQs(response.data.data);
        setCount(response.data.meta.pagination.total);
        // setFirstFaqId(response.data.data[0].attributes.order_value);
      } else {
        console.error("No Announcement data found.");
        setFAQs([]);
      }
    } catch (error) {
      console.error("Error getting Announcement:", error.message);
    }
  };

  useEffect(() => {
    getFAQs();
  }, []);
  useEffect(() => {
    setreversedSorterFormData([...sorterFormData].reverse());
  }, [sorterFormData]);
  useEffect(() => {
    console.log("reversedSorterFormData");
    console.log(reversedSorterFormData);

    if (reversedSorterFormData.length > 0) {
      console.log(reversedSorterFormData[0].order);
      console.log(
        reversedSorterFormData[reversedSorterFormData.length - 1].order
      );
      setFirstFaqId(reversedSorterFormData[0].order);
    }
  }, [reversedSorterFormData]);
  useEffect(() => {
    console.log("last", lastFaqId);
    console.log("first", firstFaqId);
    if (reversedSorterFormData.length > 0) {
      setLastFaqId(
        reversedSorterFormData[reversedSorterFormData.length - 1].order
      );
    }
  }, [firstFaqId]);

  const renderItem = (props) => {
    const { item, index, collapseIcon, handler } = props;

    return (
      <div
        style={{ ...styles, fontWeight: item.children.length ? "700" : "400" }}
      >
        {handler}
        {collapseIcon}
        <div style={{ ...cssCenter, color: "LightSlateGray", width: "3rem" }}>
          <FormatLineSpacingIcon />
        </div>

        <div
          style={{
            padding: ".5rem",
            flex: 1,
          }}
        >
          {item.title}
          {/* {item.order} - {item.title} */}
        </div>
      </div>
    );
  };
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const handleOrder = () => {
    const listMenu = [];
    let i = 0;
    faqs.map(async (item, index) => {
      console.log(index, item);
      const menuItem = {
        id: item.id,
        title: item.attributes.title,
        order: item.attributes.order_value,
      };
      ++i;
      console.log("burada" + i);
      listMenu.push(menuItem);
      // const data = {
      //   data: {
      //     order_value: (Number(index) + 1).toString(),
      //   },
      // };
      // const response = await axios.put(`${baseUrl}/blocks/${item.id}`, data, {
      //   headers: {
      //     Authorization: `Bearer ${apiToken}`,
      //   },
      // });
    });
    setSorterFormData(listMenu);
    setShow(true);
  };

  const changeDragItem = (e) => {
    console.log(e);
    // setSorterFormData(e.items);
    setreversedSorterFormData(e.items);
    // console.log(JSON.stringify(e), e.length);
  };

  const saveOrder = async () => {
    // console.log("sorterFormData");
    // console.log(sorterFormData);
    setdisabledSave(true);
    const listMenu = [];
    // sorterFormData.map(async (item, index) => {
    //   // console.log(index, item);
    //   // console.log("eski sırası - " + item.order);
    //   // console.log("yeni sırası - " + (index + firstFaqId));

    //   const menuItem = {
    //     id: item.id,
    //     title: item.title,
    //     order: index + firstFaqId,
    //   };
    //   listMenu.push(menuItem);
    //   const data = { data: { order: index + firstFaqId } };
    //   const response = await axios.put(`${baseUrl}/contents/${item.id}`, data, {
    //     headers: {
    //       Authorization: `Bearer ${apiToken}`,
    //     },
    //   });
    //   console.log(response);
    // });
    for (const [index, item] of reversedSorterFormData.entries()) {
      const menuItem = {
        id: item.id,
        title: item.title,
        order: Number(index) + Number(firstFaqId),
      };
      // console.log(
      //   "----------------------------------------------------------------"
      // );
      // console.log("sorterFormData.length", sorterFormData.length);
      // console.log("index", index);
      // console.log(
      //   "----------------------------------------------------------------"
      // );
      // console.log("item", item);
      // console.log("menuItem", menuItem);
      // console.log(
      //   "----------------------------------------------------------------"
      // );
      listMenu.push(menuItem);
      const data = {
        data: { order_value: (Number(index) + Number(firstFaqId)).toString() },
      };

      try {
        const response = await axios.put(`${baseUrl}/blocks/${item.id}`, data, {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          },
        });
        console.log(response);
      } catch (error) {
        console.error(`Error updating item ${item.id}:`, error);
      }
    }
    // getFAQs();
    setdisabledSave(false);
    setShow(false);
  };

  const handleClose = () => {
    setShow(false);
    // getFAQs();
  };

  useEffect(() => {
    getFAQs(page, rowsPerPage);
  }, [show]);

  return (
    <>
      <Box sx={{ backgroundColor: "#fff", padding: "20px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Stack spacing={1}>
              <InputLabel htmlFor="action-signup">Durum</InputLabel>
              <Select
                value={status}
                onChange={handleChangeStatus}
                name="action"
                fullWidth
              >
                <MenuItem value={"all"}>Tüm Duyurular</MenuItem>
                <MenuItem value={"true"}>Aktif</MenuItem>
                <MenuItem value={"false"}>Pasif</MenuItem>
              </Select>
            </Stack>
          </Grid>{" "}
          {/* <Grid item xs={12} md={3}>
            <Stack spacing={1}>
              <InputLabel htmlFor="content-type-select-label">
                Soru Türü
              </InputLabel>
              <FormControl>
                <Select
                  labelId="content-type-select-label"
                  id="content-type-select"
                  value={selectedContentTypeValue}
                  onChange={(event) => {
                    setSelectedContentTypeValue(event.target.value);
                    handleChangeSelectedContentType(event); // handleChange fonksiyonunu çağırarak form değerlerini güncelle
                  }}
                  name="content_type"
                  fullWidth
                >
                  <MenuItem value="10">Sıkça Sorulan Sorular</MenuItem>
                  <MenuItem value="11">Hizmetler El Kitabı</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid> */}
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              alignItems: "end",
              display: "flex",
              justifyContent: "start",
            }}
          >
            <Stack spacing={1}>
              <OutlinedInput
                id="search"
                value={searchValue}
                onChange={handleSearchChange}
                fullWidth
                startAdornment={
                  <InputAdornment position="start" sx={{ mr: -0.5 }}>
                    <SearchOutlined style={{ color: "#908d8d" }} />
                  </InputAdornment>
                }
                aria-describedby="header-search-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                placeholder="Ara..."
              />
            </Stack>
          </Grid>{" "}
          <Grid
            item
            xs={12}
            md={5}
            sx={{ alignItems: "end", display: "flex", justifyContent: "end" }}
          >
            <Button
              variant="contained"
              onClick={() => handleOrder()}
              // style={{ backgroundColor: "#ce93d8" }}
              sx={{
                backgroundColor: "#e076ef",
                "&:hover": {
                  backgroundColor: "#a33db2",
                },
                marginRight: "5px",
              }}
            >
              <MultipleStopIcon /> Sıralamayı Düzenle
            </Button>

            <Button
              variant="contained"
              sx={{
                backgroundColor: "#b388eb",
                "&:hover": {
                  backgroundColor: "#b388ebb5",
                },
              }}
              onClick={() => handleClickGoAdd()}
            >
              Yeni Duyuru Ekle
            </Button>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Duyurular</TableCell>
                <TableCell>Durum</TableCell>
                <TableCell sx={{ width: "100px" }}>İşlemler</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {faqs.length > 0 ? (
                faqs.map((faq) => (
                  <TableRow key={faq.id}>
                    <TableCell>
                      {faq.attributes.order_value !== null
                        ? Number(faq.attributes.order_value) + 1
                        : ""}{" "}
                      -{faq.attributes.title}
                    </TableCell>
                    <TableCell>
                      {faq.attributes.status ? "Aktif" : "Pasif"}
                    </TableCell>
                    <TableCell>
                      <EditLink faqId={faq.id} />
                      <IconButton
                        onClick={() => handleDeleteClick(faq.id)}
                        aria-label="delete"
                        style={{ color: "#6c757d" }}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <Dialog
                        open={deleteDialogOpen[faq.id] || false}
                        onClose={handleDeleteCancel}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {"Confirmation"}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Bu kayıdı silmek istediğinizden emin misiniz? (
                            {faq.attributes.title})
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleDeleteCancel} color="primary">
                            Hayır
                          </Button>
                          <Button
                            onClick={handleDeleteConfirm}
                            color="primary"
                            autoFocus
                          >
                            Evet
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    No announcement found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100, 200, 500]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Dialog
        fullScreen
        open={show}
        onClose={() => handleClose()}
        // TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", backgroundColor: "#13c2c229" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleClose()}
              disabled={disabledSave}
              aria-label="close"
            >
              <HighlightOffIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              <p>
                <b> NOT:</b> <u>En alt</u> sıradaki duyuru, bu sıralamaya göre,
                bu sıralama içerisinde <u>en üst</u> sırada görünecektir. Şu
                anda{" "}
                <b>
                  {firstFaqId} - {lastFaqId}
                </b>{" "}
                sıralar arasındaki sıralamayı değiştiriyorsunuz.{" "}
              </p>
            </Typography>
            <Button
              autoFocus
              color="info"
              onClick={saveOrder}
              disabled={disabledSave}
              variant="contained"
            >
              <CheckCircleOutlineIcon /> Sıralamayı Kaydet
            </Button>
          </Toolbar>
        </AppBar>
        <Grid container>
          <Grid item xs={3}></Grid>
          <Grid
            className="faq-nestable"
            item
            xs={6}
            sx={{ paddingTop: "25px" }}
          >
            <Box sx={{ maxHeight: "700px", overflowY: "auto" }}>
              <Nestable
                items={reversedSorterFormData}
                renderItem={renderItem}
                onChange={changeDragItem}
              />
            </Box>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default AnnouncementList;
