import axios from "axios";

const strapi = axios.create();
const user = JSON.parse(localStorage.getItem("user"));
// const apiToken = process.env.REACT_APP_API_TOKEN;
let apiToken = "";
if(user){
  apiToken=user.jwt
}
const baseUrl = process.env.REACT_APP_API_KEY;

strapi.defaults.baseURL = `${baseUrl}`;

strapi.defaults.headers = {
  //    'Content-Type': 'multipart/form-data',
  Authorization: `Bearer ${apiToken}`,
};

//All request will wait 2 seconds before timeout
strapi.defaults.timeout = 2000;

strapi.defaults.withCredentials = true;

export default strapi;
