import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  Typography,
  TextField,
  FormHelperText,
  FormControl,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import strapi from "../../utils/strapi";
import JoditEditor from "jodit-react";
import { activeItem } from "store/reducers/menu";
import { useDispatch } from "react-redux";

const AnnouncementAdd = () => {
  const dispatch = useDispatch();
  const user_ = JSON.parse(localStorage.getItem("user"));
  const apiToken = user_.jwt;
  const baseUrl = process.env.REACT_APP_API_KEY;
  const mediaUrl = process.env.REACT_APP_API_MEDIA_URL;
  const [lang, setLang] = useState([]);
  const [otherLang, setOtherLang] = useState([]);
  const [selectedLang, setSelectedLang] = useState({});
  const [selectedLangID, setSelectedLangID] = useState(0);
  const [procId, setProcId] = useState(0);
  const [slug, setSlug] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [contentType, setSelectedContentType] = useState("");
  const [selectedContentTypeValue, setSelectedContentTypeValue] = useState("");

  const { id } = useParams();

  const [initValue, setInitValue] = useState({
    title: "",
    content: "",
    short_content: "",
    image: "",
    status: true,
    content_type: "",
    url: "",
    locale: "tr",
  });

  const getFaqs = async (id) => {
    // try {
    const response = await strapi.get(
      `/blocks/${id}?populate=localizations,image,gallery,content_type`
    );

    if (response.data) {
      console.log("response burada", response.data);
      setInitValue({
        title: response.data.data.attributes.title
          ? response.data.data.attributes.title
          : "",
        content: response.data.data.attributes.content
          ? response.data.data.attributes.content
          : "",
        short_content: response.data.data.attributes.description
          ? response.data.data.attributes.description
          : "",
        image:
          response.data.data.attributes.image &&
          response.data.data.attributes.image.data &&
          response.data.data.attributes.image.data.id,
        status: response.data.data.attributes.status,
        content_type: response.data.data.attributes.content_type.data.id,
        url: response.data.data.attributes.url,
        locale: response.data.data.attributes.locale,
      });
      setSlugUrl(response.data.data.attributes.url);
      setSelectedContentTypeValue(
        response.data.data.attributes.content_type.data.id
      );

      if (
        response.data.data.attributes.localizations &&
        response.data.data.attributes.localizations.data
      ) {
        const newData = response.data.data.attributes.localizations.data.map(
          (val) => ({
            id: val.id.toString(),
            lang: val.attributes.locale,
          })
        );

        setOtherLang((prev) => {
          newData.forEach((newItem) => {
            if (!prev.some((item) => item.lang === newItem.lang)) {
              prev.push(newItem);
            }
          });
          return prev;
        });
      }
    }
  };
  const fetchLanguage = async () => {
    try {
      const response = await strapi.get(`/languages`);
      if (response.status === 200 && response.data) {
        setLang(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching position:", error.message);
    }
  };

  useEffect(() => {
    if (lang.length) {
      setSelectedLang(lang[0]);
    }
  }, [lang]);

  // useEffect(() => {
  //   console.log("selected content type");
  //   console.log(selectedContentTypeValue);
  // }, [selectedContentTypeValue]);

  useEffect(() => {
    fetchLanguage();
    setOtherLang([{ id: id, lang: "tr" }]);
    console.log("selected content type");
    console.log(selectedContentTypeValue);
    dispatch(activeItem({ openItem: ["announcements"] }));
    if (id) {
      getFaqs(id);
      setProcId(id);
      console.log("content");
      console.log(initValue);
    } else {
      setInitValue({
        title: "",
        content: "",
        status: true,
        content_type: "",
        url: "",
        locale: "tr",
      });
    }
  }, [id]);

  useEffect(() => {
    if (selectedLangID) {
      console.log("selectedLangID girdi");
      const langItem = otherLang.find((item) => item.lang === selectedLangID);
      if (langItem && langItem.id) {
        console.log("id selectedLangID girdi");
        getFaqs(langItem.id);
        setProcId(langItem.id);
      } else {
        setProcId(0);
        setInitValue({
          title: "",
          content: "",
          status: true,
          content_type: "",
          url: "",
          locale: selectedLang.lang,
        });
      }
    }
  }, [selectedLangID]);

  const handleSubmit = async (
    values,
    { setErrors, setSubmitting, setStatus }
  ) => {
    setSubmitting(true);

    const contentTypeId = "2";
    const data = {
      data: {
        title: values.title,
        content: values.content,
        //short_content: values.short_content,
        status: values.status,
        content_type: contentTypeId,
        url: "/not-url" + values.title,
        description: values.short_content,
        locale: selectedLangID ? selectedLangID : "tr",
      },
    };
    try {
      let response;
      if (id) {
        response = await axios.put(`${baseUrl}/blocks/${procId}`, data, {
          headers: { Authorization: `Bearer ${apiToken}` },
        });
      } else {
        const resp = await strapi.get(
          `/blocks?filters[content_type]=2&sort=order_value:desc&populate=*,block_position,content_type`
        );
        console.log(resp.data.data[0].attributes.order_value);
        if (resp.data.data) {
          data.data.order_value =
            Number(resp.data.data[0].attributes.order_value) + 1;
        }
        response = await axios.post(`${baseUrl}/blocks`, data, {
          headers: { Authorization: `Bearer ${apiToken}` },
        });

        if (!id) {
          // Localization işlemi
          const localizationResponse = await axios.post(
            `${baseUrl}/blocks/${response.data.data.id}/localizations`,
            { locale: "en", url: data.data.url + "-en" },
            { headers: { Authorization: `Bearer ${apiToken}` } }
          );

          if (
            localizationResponse.status !== 200 ||
            !localizationResponse.data.id
          ) {
            throw new Error("Localization failed");
          }
        }
      }

      if (response.status === 200 && response.data.data.id) {
        setStatus({ success: true });
        setSubmitting(false);
        if (id) {
          Swal.fire("Güncelleme Başarılı!").then(() => {
            window.location.href = "/announcement/edit/" + id;
          });
        } else {
          Swal.fire("Kayıt Başarılı!").then(() => {
            window.location.href = "/announcements";
          });
        }
      } else {
        throw new Error("Response status or data.id is invalid");
      }
    } catch (error) {
      console.error("API request failed:", error);
      setErrors({ submit: "Bir hata oluştu, lütfen tekrar deneyin." });
      setSubmitting(false);
    }
  };
  //FILE UPOAD Jodit
  const config = {
    readonly: false, // Düzenleme modu
    toolbar: true, // Araç çubuğu
    uploader: {
      insertImageAsBase64URI: false,
      url: `${baseUrl}/upload`,
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
      prepareData: function (formData) {
        var file = formData.getAll("files[0]")[0];
        formData.delete("files[0]");
        formData.append("files", file);
        formData.delete("path");
        formData.delete("source");
        return formData;
      },
      isSuccess: function (resp) {
        console.log("upload succecc");
        if (resp[0].mime === "application/pdf") {
          setInitValue({
            title: resp[0].name.replace(".pdf", ""),
            short_content: resp[0].url,
            content: "",
            image: "",
            status: true,
            content_type: "",
            url: "",
            locale: "tr",
          });
        }

        return resp[0].id ? true : false;
      },
      getMessage: function (resp) {
        return "Bir hata oluştu";
      },
      process: function (resp) {
        const gorsel =
          resp[0].formats && resp[0].formats.small
            ? resp[0].formats.small.url
            : resp[0].url;

        return {
          files: [`${gorsel}`],
          isImages: [true],
          baseurl: `${mediaUrl}`,
          error: resp[0].ext,
          msg: resp[0].size,
        };
      },
    },
  };

  // const slugify = (text) => {
  //   console.log("slugify", text);
  //   setSlug(
  //     "/" +
  //       text
  //         .toString()
  //         .toLowerCase()
  //         .replace(/\s+/g, "-") // Boşlukları tireye çevir
  //         .replace(/ı/g, "i")
  //         .replace(/ö/g, "o")
  //         .replace(/ü/g, "u")
  //         .replace(/ç/g, "c")
  //         .replace(/ş/g, "s")
  //         .replace(/ğ/g, "g")
  //         .replace(/[^\w\-]+/g, "") // Harf, sayı, tire ve alt çizgi dışındaki karakterleri kaldır
  //         .replace(/\-\-+/g, "-") // Birden fazla tireyi tek tireye çevir
  //         .replace(/^-+/, "") // Başlangıçtaki tireleri kaldır
  //         .replace(/-+$/, "") + // Sonundaki tireleri kaldır
  //       "/"
  //   );
  // };

  return (
    <Box sx={{ backgroundColor: "#fff", padding: "20px" }}>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography variant="h4" gutterBottom>
          {id ? "Duyuru Düzenle" : "Yeni Duyuru Ekle"}
        </Typography>

        <InputLabel htmlFor="lang-select"></InputLabel>
        <Select
          labelId="lang-select-label"
          id="lang-select"
          value={selectedLang}
          onChange={(event) => {
            setSelectedLang(event.target.value);
            setSelectedLangID(event.target.value.attributes.locale_code);
          }}
          sx={{ width: "110px" }}
        >
          {lang.map((option) => (
            <MenuItem key={option.attributes.locale_code} value={option}>
              {option.attributes.locale_code}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Formik
        initialValues={{
          title: initValue.title,
          content: initValue.content,
          short_content: initValue.short_content,
          status: initValue.status,
          content_type: initValue.content_type,
          url: initValue.url,
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().max(255).required("Description is required"),
        })}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          keyDown,
          touched,
          values,
          setFieldValue,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12} sx={{ textAlign: "end" }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.status}
                      onChange={handleChange}
                      name="status"
                    />
                  }
                  label="Status"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="title-input">Duyuru*</InputLabel>
                  <OutlinedInput
                    id="title-input"
                    type="text"
                    value={values.title}
                    name="title"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter title"
                    fullWidth
                    error={Boolean(touched.title && errors.title)}
                  />
                  {touched.title && errors.title && (
                    <Typography color="error">{errors.title}</Typography>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="short_content-input">
                    Yönlenirme Url*
                  </InputLabel>
                  <OutlinedInput
                    id="short_content-input"
                    type="text"
                    value={values.short_content}
                    name="short_content"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter short content"
                    fullWidth
                    error={Boolean(
                      touched.short_content && errors.short_content
                    )}
                  />
                  {touched.short_content && errors.short_content && (
                    <Typography color="error">
                      {errors.short_content}
                    </Typography>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack spacing={2}>
                  <InputLabel htmlFor="description-signup">İçerik</InputLabel>
                  <JoditEditor
                    value={values.content}
                    config={config}
                    tabIndex={1}
                    onBlur={(newContent) =>
                      handleChange({
                        target: { name: "content", value: newContent },
                      })
                    }
                  />
                </Stack>
              </Grid>
            </Grid>
            <Box sx={{ mt: 3 }}>
              <Button
                disableElevation
                // color="primary"
                style={{ backgroundColor: "#b3dee2" }}
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {id ? "Güncelle" : "Kayıt Oluştur"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default AnnouncementAdd;
